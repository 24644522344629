import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import Nav from '../../components/nav'
import SEO from '../../components/seo'
import Footer from '../../components/footer'
import Typography from '../../components/typography'
import Box from '../../components/box'
import IntegrationFaqs from '../../components/integrationFaqs'
import '../code-block.css'
import { useRemoveHotjarScript } from '../../utils/hooks/useHotjarScript'

const IntegrationPostPage = props => {
  const { pageContext } = props
  const {
    integration: {
      node: { html, frontmatter },
    },
    tags = [],
  } = pageContext
  const {
    label,
    description,
    icon,
    status,
    website,
    plan,
    categorySlugs,
    documentation,
    faqs,
  } = frontmatter

  const defaultSlug = categorySlugs && categorySlugs[0]
  const labelBySlug = useMemo(() => {
    const labelBySlug = new Map()
    for (let i = 0; i < tags.length; i++) {
      labelBySlug.set(tags[i].slug, tags[i].label)
    }
    return labelBySlug
  }, [tags])
  const category = labelBySlug.get(defaultSlug)

  useRemoveHotjarScript()

  return (
    <>
      <SEO title={`${label}`} description={description} />
      <Nav />
      <MainContainer>
        <BannerContainer>
          <HeaderContainer>
            <Box
              display="flex"
              justifyContent="space-between"
              className="banner-header-button"
            >
              <Box
                display="flex"
                alignItems="center"
                className="banner-header-icon"
              >
                <img
                  src={icon && icon.publicURL}
                  alt={label}
                  style={{
                    width: '3.75rem',
                    height: '3.75rem',
                    marginRight: '1rem',
                  }}
                ></img>
                <Box className="banner-title-description">
                  <Typography
                    textType="heading-medium"
                    fontWeight="500"
                    component="h1"
                    style={{ margin: 0 }}
                  >
                    {label}
                  </Typography>
                  <Typography
                    textType="paragraph-medium"
                    colorType="core.content.tertiary"
                  >
                    {description}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Link
              to="/integrations"
              style={{ color: 'black', textDecoration: 'none' }}
            >{`<- Integrations`}</Link>
          </HeaderContainer>
        </BannerContainer>
        <ContentContainer>
          <DetailsContainer>
            <Typography
              textType="heading-xSmall"
              component="h3"
              fontWeight="500"
            >
              Details
            </Typography>
            <Box
              style={{
                borderBottom: '0.0625rem solid #e2e2e2',
                marginBottom: '1.25rem',
              }}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="1rem"
              alignItems="center"
            >
              <Typography textType="label-medium" fontWeight="500">
                Status
              </Typography>
              <Typography
                style={{
                  background: '#000000',
                  borderRadius: '0.25rem',
                  color: '#FFFFFF',
                  padding: '0.125rem 0.5rem',
                }}
              >
                {status}
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="1rem"
              alignItems="center"
            >
              <Typography textType="label-medium" fontWeight="500">
                Website
              </Typography>
              <a
                href={`https://${website}`}
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'no-wrap',
                  color: '#757575',
                  maxWidth: '190px',
                }}
              >
                {website}
              </a>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="1rem"
              alignItems="center"
            >
              <Typography textType="label-medium" fontWeight="500">
                Plan
              </Typography>
              <Typography colorType="core.content.tertiary">{plan}</Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              marginBottom="1rem"
              alignItems="center"
            >
              <Typography textType="label-medium" fontWeight="500">
                Category
              </Typography>
              <Typography colorType="core.content.tertiary">
                {category}
              </Typography>
            </Box>
            {documentation && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography textType="label-medium" fontWeight="500">
                  Documentation
                </Typography>
                <Typography colorType="core.content.tertiary">
                  {documentation}
                </Typography>
              </Box>
            )}
          </DetailsContainer>
          <DocumentationContainer>
            <div dangerouslySetInnerHTML={{ __html: html }} id="integrations" />
            <Box marginBottom="3.75rem" />
            {faqs && (
              <>
                <Typography
                  textType="heading-small"
                  component="h4"
                  fontWeight="500"
                >
                  FAQ
                </Typography>
                <IntegrationFaqs faqs={faqs} />
              </>
            )}
          </DocumentationContainer>
        </ContentContainer>
      </MainContainer>
      <Footer />
    </>
  )
}

const MainContainer = styled.main`
  min-height: 100%;
  margin: auto;
  max-width: 100vw;
`
const BannerContainer = styled.div`
  background-color: #f6f6f6;
  height: auto;

  .banner-header-button {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .banner-header-icon {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .banner-title-description {
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 1rem;
  }

  @media ${props => props.theme.device.tablet} {
    height: 16.0625rem;

    .banner-header-button {
      flex-direction: row;
      margin: 0;
    }

    .banner-header-icon {
      flex-direction: row;
      margin: 0;
    }

    .banner-title-description {
      flex-direction: column;
      text-align: inherit;
      margin: 0;
    }
  }
`

const HeaderContainer = styled.div`
  width: 57.5rem;
  max-width: 100vw;
  flex-wrap: wrap;
  margin: auto;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  box-sizing: border-box;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;

  @media ${props => props.theme.device.tablet} {
    padding-left: 0;
    padding-right: 0;
    padding-top: 4.375rem;
    padding-bottom: 1.562rem;
  }
`

const ContentContainer = styled.div`
  display: flex;
  margin: auto;
  max-width: 100vw;
  flex-direction: column;
  padding: 2rem 1rem;
  box-sizing: border-box;

  @media ${props => props.theme.device.tablet} {
    padding: 3.125rem 0 5rem 0;
    flex-direction: row;
    width: 85vw;
  }

  @media ${props => props.theme.device.laptopL} {
    max-width: 75rem;
  }
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  width: 100%;

  @media ${props => props.theme.device.tablet} {
    margin-right: 4.0625rem;
    width: 16.25rem;
  }
`

const DocumentationContainer = styled.div`
  width: 48.4375rem;
  max-width: 100%;
  box-sizing: border-box;
`

export default IntegrationPostPage
