import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import Typography from './typography'
import QuestionIcon from '../assets/svg/faq-bullet.svg'
import Box from './box'

const Expandable = styled.button`
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 0.9375rem;
  display: flex;
  justify-content: space-between;
  padding: 0rem 0rem;
  padding-bottom: 1.25rem;
  background: none;

  &:after {
    content: '\\02795'; /* Unicode character for "plus" sign (+) */
    font-size: 0.8125rem;
    color: white;
    float: right;
    margin-left: 0.3125rem;
  }

  .content {
    display: none;
    overflow: hidden;
  }

  .preview {
    display: block;
  }

  ${props =>
    props.expanded &&
    css`
      &:after {
        content: '\\2796'; /* Unicode character for "minus" sign (-) */
      }
      .content {
        display: block;
      }
      .preview {
        display: none !important;
      }
    `}
`

const ExpandButton = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded(!expanded)

  return (
    <Expandable type="button" onClick={toggleExpanded} expanded={expanded}>
      <div>
        <Typography
          textType="paragraph-medium"
          style={{
            margin: '0rem',
            display: 'flex',
            alignItems: 'center',
          }}
          fontWeight={500}
          component="p"
          colorType="core.content.tertiary"
        >
          <QuestionIcon />
          <Box marginRight="1rem" />
          {question}
        </Typography>
        <div className="content">
          <Typography
            textType="paragraph-small"
            colorType="core.content.secondary"
            style={{
              display: 'block',
              marginTop: '.5rem',
              marginLeft: '2.25rem',
            }}
            component="p"
          >
            {answer}
          </Typography>
        </div>
      </div>
    </Expandable>
  )
}

const IntegrationFaqs = ({ faqs = [] }) => {
  return (
    <div>
      {faqs.map((faq, index) => (
        <ExpandButton
          question={faq.q}
          answer={faq.a}
          key={`${faq.q}${index}`}
        />
      ))}
    </div>
  )
}

export default IntegrationFaqs
